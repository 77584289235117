
import * as THREE from "../../libs/three.js/build/three.module.js";
import {GeoJSONExporter} from "../exporter/GeoJSONExporter.js"
import {DXFExporter} from "../exporter/DXFExporter.js"
import {CSVExporter} from "../exporter/CSVExporter.js"
import {Volume, SphereVolume} from "../utils/Volume.js"
import {PolygonClipVolume} from "../utils/PolygonClipVolume.js"
import {PropertiesPanel} from "./PropertyPanels/PropertiesPanel.js"
import {PointCloudTree} from "../PointCloudTree.js"
import {Profile} from "../utils/Profile.js"
import {Measure} from "../utils/Measure.js"
import {Annotation} from "../Annotation.js"
import {CameraMode, ClipTask, ClipMethod} from "../defines.js"
import {ScreenBoxSelectTool} from "../utils/ScreenBoxSelectTool.js"
import {Utils} from "../utils.js"
import {CameraAnimation} from "../modules/CameraAnimation/CameraAnimation.js"
import {HierarchicalSlider} from "./HierarchicalSlider.js"
import {OrientedImage} from "../modules/OrientedImages/OrientedImages.js";
import {Images360} from "../modules/Images360/Images360.js";
import { SdioFetch, SdioState, SdioUtils } from "../Potree.js";

import {Line2} from "../../libs/three.js/lines/Line2.js";
import {LineGeometry} from "../../libs/three.js/lines/LineGeometry.js";
import {LineMaterial} from "../../libs/three.js/lines/LineMaterial.js";

import JSON5 from "../../libs/json5-2.1.3/json5.mjs";

var sdAnnotations = undefined;
var features = undefined;
var km = undefined;

export class Sidebar{

	constructor(viewer){
		this.viewer = viewer;

		this.measuringTool = viewer.measuringTool;
		this.profileTool = viewer.profileTool;
		this.volumeTool = viewer.volumeTool;

		this.dom = $("#sidebar_root");
	}

	createToolIcon(icon, title, callback){
		let element = $(`
			<img src="${icon}"
				style="width: 32px; height: 32px"
				class="button-icon"
				data-i18n="${title}" />
		`);

		element.click(callback);

		return element;
	}

	init(){

		this.initAccordion();
		this.initAppearance();
		this.initToolbar();
		this.initScene();
		this.initNavigation();
		this.initFilters();
		this.initClippingTool();
		this.initSettings();
		this.initAnnotationsManager();

		$('#potree_version_number').html(Potree.version.major + "." + Potree.version.minor + Potree.version.suffix);
	}

		

	initToolbar(){

		// ANGLE
		let elToolbar = $('#tools');
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/angle.png',
			'[title]tt.angle_measurement',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: false,
					showAngles: true,
					showArea: false,
					closed: true,
					maxMarkers: 3,
					name: 'Angle'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// POINT
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/point.svg',
			'[title]tt.point_measurement',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: false,
					showAngles: false,
					showCoordinates: true,
					showArea: false,
					closed: true,
					maxMarkers: 1,
					name: 'Point'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// DISTANCE
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/distance.svg',
			'[title]tt.distance_measurement',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: true,
					showArea: false,
					closed: false,
					name: 'Distance'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// HEIGHT
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/height.svg',
			'[title]tt.height_measurement',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: false,
					showHeight: true,
					showArea: false,
					closed: false,
					maxMarkers: 2,
					name: 'Height'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// CIRCLE
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/circle.svg',
			'[title]tt.circle_measurement',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: false,
					showHeight: false,
					showArea: false,
					showCircle: true,
					showEdges: false,
					closed: false,
					maxMarkers: 3,
					name: 'Circle'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// AZIMUTH
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/azimuth.svg',
			'Azimuth',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: false,
					showHeight: false,
					showArea: false,
					showCircle: false,
					showEdges: false,
					showAzimuth: true,
					closed: false,
					maxMarkers: 2,
					name: 'Azimuth'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// AREA
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/area.svg',
			'[title]tt.area_measurement',
			() => {
				$('#menu_measurements').next().slideDown();
				let measurement = this.measuringTool.startInsertion({
					showDistances: true,
					showArea: true,
					closed: true,
					name: 'Area'});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// VOLUME
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/volume.svg',
			'[title]tt.volume_measurement',
			() => {
				let volume = this.volumeTool.startInsertion(); 

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === volume.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// SPHERE VOLUME
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/sphere_distances.svg',
			'[title]tt.volume_measurement',
			() => { 
				let volume = this.volumeTool.startInsertion({type: SphereVolume}); 

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === volume.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// PROFILE
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/profile.svg',
			'[title]tt.height_profile',
			() => {
				$('#menu_measurements').next().slideDown(); ;
				let profile = this.profileTool.startInsertion();

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === profile.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// ANNOTATION
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/annotation.svg',
			'[title]tt.annotation',
			() => {
				$('#menu_measurements').next().slideDown(); ;
				let annotation = this.viewer.annotationTool.startInsertion();

				let annotationsRoot = $("#jstree_scene").jstree().get_json("annotations");
				let jsonNode = annotationsRoot.children.find(child => child.data.uuid === annotation.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// REMOVE ALL
		elToolbar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/reset_tools.svg',
			'[title]tt.remove_all_measurement',
			() => {
				this.viewer.scene.removeAllMeasurements();
			}
		));


		{ // SHOW / HIDE Measurements
			let elShow = $("#measurement_options_show");
			elShow.selectgroup({title: "Show/Hide labels"});

			elShow.find("input").click( (e) => {
				const show = e.target.value === "SHOW";
				this.measuringTool.showLabels = show;
			});

			let currentShow = this.measuringTool.showLabels ? "SHOW" : "HIDE";
			elShow.find(`input[value=${currentShow}]`).trigger("click");
		}
	}

	initScene(){

		let elScene = $("#menu_scene");
		let elObjects = elScene.next().find("#scene_objects");
		let elProperties = elScene.next().find("#scene_object_properties");
		

		{
			let elExport = elScene.next().find("#scene_export");

			let geoJSONIcon = `${Potree.resourcePath}/icons/file_geojson.svg`;
			let dxfIcon = `${Potree.resourcePath}/icons/file_dxf.svg`;
			let potreeIcon = `${Potree.resourcePath}/icons/file_potree.svg`;

			elExport.append(`
				Export: <br>
				<a href="#" download="measure.json" style="color: white; font-weight: 700; padding-top: 5px;"><img name="geojson_export_button" class="button-icon" style="height: 24px" />JSON</a>
				<a href="#" download="measure.dxf" style="color: white; margin-left: 12px; font-weight: 700; padding-top: 5px;"><img name="dxf_export_button" class="button-icon" style="height: 24px" />DXF</a>
				<a href="#" download="potree.json5" style="color: white; margin-left: 12px; font-weight: 700; padding-top: 5px;"><img name="potree_export_button" class="button-icon" style="height: 24px" />Potree</a>
				<a href="#" download="measure.csv" style="color: white; margin-left: 12px; font-weight: 700; padding-top: 5px;">CSV<img name="csv_export_button"/></a>
			`);

			let elDownloadCSV = elExport.find("img[name=csv_export_button]").parent();
			elDownloadCSV.click( (event) => {
				let scene = this.viewer.scene;
				let measurements = [...scene.measurements, ...scene.profiles, ...scene.volumes];

				if(measurements.length > 0){
					let csv = CSVExporter.toString(measurements);

					let url = window.URL.createObjectURL(new Blob([csv], {type: 'data:application/octet-stream'}));
					elDownloadCSV.attr('href', url);
				}else{
					this.viewer.postError("no measurements to export");
					event.preventDefault();
				}
			});

			let elDownloadJSON = elExport.find("img[name=geojson_export_button]").parent();
			elDownloadJSON.click( (event) => {
				let scene = this.viewer.scene;
				let measurements = [...scene.measurements, ...scene.profiles, ...scene.volumes];

				if(measurements.length > 0){
					let geoJson = GeoJSONExporter.toString(measurements);

					let url = window.URL.createObjectURL(new Blob([geoJson], {type: 'data:application/octet-stream'}));
					elDownloadJSON.attr('href', url);
				}else{
					this.viewer.postError("no measurements to export");
					event.preventDefault();
				}
			});

			let elDownloadDXF = elExport.find("img[name=dxf_export_button]").parent();
			elDownloadDXF.click( (event) => {
				let scene = this.viewer.scene;
				let measurements = [...scene.measurements, ...scene.profiles, ...scene.volumes];

				if(measurements.length > 0){
					let dxf = DXFExporter.toString(measurements);

					let url = window.URL.createObjectURL(new Blob([dxf], {type: 'data:application/octet-stream'}));
					elDownloadDXF.attr('href', url);
				}else{
					this.viewer.postError("no measurements to export");
					event.preventDefault();
				}
			});

			let elDownloadPotree = elExport.find("img[name=potree_export_button]").parent();
			elDownloadPotree.click( (event) => {

				let data = Potree.saveProject(this.viewer);
				let dataString = JSON5.stringify(data, null, "\t")

				let url = window.URL.createObjectURL(new Blob([dataString], {type: 'data:application/octet-stream'}));
				elDownloadPotree.attr('href', url);
			});
		}

		let propertiesPanel = new PropertiesPanel(elProperties, this.viewer);
		propertiesPanel.setScene(this.viewer.scene);
		
		localStorage.removeItem('jstree');

		let tree = $(`<div id="jstree_scene"></div>`);
		elObjects.append(tree);

		tree.jstree({
			'plugins': ["checkbox", "state", "sort"],
			'core': {
				"dblclick_toggle": false,
				"state": {
					"checked" : true
				},
				'check_callback': true,
				"expand_selected_onload": true
			},
			"checkbox" : {
				"keep_selected_style": true,
				"whole_node": false,
				"tie_selection": false,
			},
			'sort' : function(a, b) {
				let a1 = this.get_node(a);
				let b1 = this.get_node(b);
				if (a1 && b1) {
					if (!isNaN(a1.text) && !isNaN(b1.text)) {
							return a1.text - b1.text;
					} else {		
						if (a1.icon == b1.icon){
							return (a1.text > b1.text) ? 1 : -1;
						} else {
							return (a1.icon > b1.icon) ? 1 : -1;
						}
					}
				}
			},
		});

		let createNode = (parent, text, icon, object) => {
			let nodeID = tree.jstree('create_node', parent, { 
					"text": text, 
					"icon": icon,
					"data": object
				}, 
				"last", false, false);
			
			if(object.visible){
				tree.jstree('check_node', nodeID);
			}else{
				tree.jstree('uncheck_node', nodeID);
			}

			return nodeID;
		}

		let pcID = tree.jstree('create_node', "#", { "text": "<b>Point Clouds</b>", "id": "pointclouds"}, "last", false, false);
		let measurementID = tree.jstree('create_node', "#", { "text": "<b>Measurements</b>", "id": "measurements" }, "last", false, false);
		let annotationsID = tree.jstree('create_node', "#", { "text": "<b>Annotations</b>", "id": "annotations" }, "last", false, false);
		let otherID = tree.jstree('create_node', "#", { "text": "<b>Other</b>", "id": "other" }, "last", false, false);
		let vectorsID = tree.jstree('create_node', "#", { "text": "<b>Vectors</b>", "id": "vectors" }, "last", false, false);
		let imagesID = tree.jstree('create_node', "#", { "text": "<b> Images</b>", "id": "images" }, "last", false, false);

		tree.jstree("check_node", pcID);
		tree.jstree("check_node", measurementID);
		tree.jstree("check_node", annotationsID);
		tree.jstree("check_node", otherID);
		tree.jstree("check_node", vectorsID);
		tree.jstree("check_node", imagesID);

		tree.on('create_node.jstree', (e, data) => {
			tree.jstree("open_all");
		});

		tree.on("select_node.jstree", (e, data) => {
			let object = data.node.data;
			propertiesPanel.set(object);

			// Update Edit point
			if (object && object instanceof Annotation){
				SdioUtils.openEdit(object);
			} else if (object && object instanceof Measure && object.type === "Object3D"
			 && object.name != 'Distance' && object.name != 'Height' && object.name != 'Angle'){
				SdioUtils.openEdit(object);
			}

			this.viewer.inputHandler.deselectAll();

			if(object instanceof Volume){
				this.viewer.inputHandler.toggleSelection(object);
			}

			$(this.viewer.renderer.domElement).focus();
		});

		tree.on("deselect_node.jstree", (e, data) => {
			propertiesPanel.set(null);
		});

		tree.on("delete_node.jstree", (e, data) => {
			propertiesPanel.set(null);
		});

		tree.on('dblclick','.jstree-anchor', (e) => {

			let instance = $.jstree.reference(e.target);
			let node = instance.get_node(e.target);
			let object = node.data;

			// ignore double click on checkbox
			if(e.target.classList.contains("jstree-checkbox")){
				return;
			}

			if(object instanceof PointCloudTree){
				let box = this.viewer.getBoundingBox([object]);
				let node = new THREE.Object3D();
				node.boundingBox = box;
				this.viewer.zoomTo(node, 1, 500);
			}else if(object instanceof Measure){
				let points = object.points.map(p => p.position);
				let box = new THREE.Box3().setFromPoints(points);
				// if(box.getSize(new THREE.Vector3()).length() > 0){
				// 	let node = new THREE.Object3D();
				// 	node.boundingBox = box;
				// 	this.viewer.zoomTo(node, 2, 500);
				// }
				if (object.points[0].position.x + object.points[0].position.y + object.points[0].position.z != 0){
					const box = new THREE.Box3();
					box.min = new THREE.Vector3(object.points[0].position.x - 1,object.points[0].position.y - 1,object.points[0].position.z - 1);
					box.max = new THREE.Vector3(object.points[0].position.x + 1,object.points[0].position.y + 1,object.points[0].position.z + 1);
					object.boundingBox = box;
					viewer.zoomTo(object, 3, 0);
					// viewer.controls.stop();
				}

			}else if(object instanceof Profile){
				let points = object.points;
				let box = new THREE.Box3().setFromPoints(points);
				if(box.getSize(new THREE.Vector3()).length() > 0){
					let node = new THREE.Object3D();
					node.boundingBox = box;
					this.viewer.zoomTo(node, 1, 500);
				}
			}else if(object instanceof Volume){
				
				let box = object.boundingBox.clone().applyMatrix4(object.matrixWorld);

				if(box.getSize(new THREE.Vector3()).length() > 0){
					let node = new THREE.Object3D();
					node.boundingBox = box;
					this.viewer.zoomTo(node, 1, 500);
				}
			}else if(object instanceof Annotation){
				object.moveHere(this.viewer.scene.getActiveCamera());
			}else if(object instanceof PolygonClipVolume){
				let dir = object.camera.getWorldDirection(new THREE.Vector3());
				let target;

				if(object.camera instanceof THREE.OrthographicCamera){
					dir.multiplyScalar(object.camera.right)
					target = new THREE.Vector3().addVectors(object.camera.position, dir);
					this.viewer.setCameraMode(CameraMode.ORTHOGRAPHIC);
				}else if(object.camera instanceof THREE.PerspectiveCamera){
					dir.multiplyScalar(this.viewer.scene.view.radius);
					target = new THREE.Vector3().addVectors(object.camera.position, dir);
					this.viewer.setCameraMode(CameraMode.PERSPECTIVE);
				}
				
				this.viewer.scene.view.position.copy(object.camera.position);
				this.viewer.scene.view.lookAt(target);
			}else if(object.type === "SpotLight"){
				let distance = (object.distance > 0) ? object.distance / 4 : 5 * 1000;
				let position = object.position;
				let target = new THREE.Vector3().addVectors(
					position, 
					object.getWorldDirection(new THREE.Vector3()).multiplyScalar(distance));

				this.viewer.scene.view.position.copy(object.position);
				this.viewer.scene.view.lookAt(target);
			}else if(object instanceof THREE.Object3D){
				let box = new THREE.Box3().setFromObject(object);

				if(box.getSize(new THREE.Vector3()).length() > 0){
					let node = new THREE.Object3D();
					node.boundingBox = box;
					this.viewer.zoomTo(node, 1, 500);
				}
			}else if(object instanceof OrientedImage){
				// TODO zoom to images

				// let box = new THREE.Box3().setFromObject(object);

				// if(box.getSize(new THREE.Vector3()).length() > 0){
				// 	let node = new THREE.Object3D();
				// 	node.boundingBox = box;
				// 	this.viewer.zoomTo(node, 1, 500);
				// }
			}else if(object instanceof Images360){
				// TODO
			}else if(object instanceof Geopackage){
				// TODO
			}
		});

		tree.on("uncheck_node.jstree", (e, data) => {
			let object = data.node.data;

			if(object){
				object.visible = false;
			}

			
			for (let i = 0; i < data.node.children.length; i += 1) {
				const node = tree.jstree('get_node', data.node.children[i])
				if (node.data) {
					node.data.visible = false;
				}
			}
		});

		tree.on("check_node.jstree", (e, data) => {
			let object = data.node.data;

			if(object){
				object.visible = true;
			}

			for (let i = 0; i < data.node.children.length; i += 1) {
				const node = tree.jstree('get_node', data.node.children[i])
				if (node.data) {
					node.data.visible = true;
				}
			}
		});


		let onPointCloudAdded = (e) => {
			let pointcloud = e.pointcloud;
			let cloudIcon = `${Potree.resourcePath}/icons/cloud.svg`;
			let node = createNode(pcID, pointcloud.name, cloudIcon, pointcloud);

			pointcloud.addEventListener("visibility_changed", () => {
				if(pointcloud.visible){
					tree.jstree('check_node', node);
				}else{
					tree.jstree('uncheck_node', node);
				}
			});
		};

		let onMeasurementAdded = (e) => {
			let measurement = e.measurement;
			let icon = Utils.getMeasurementIcon(measurement);
			createNode(measurementID, measurement.name, icon, measurement);

		};

		let onVolumeAdded = (e) => {
			let volume = e.volume;
			let icon = Utils.getMeasurementIcon(volume);
			let node = createNode(measurementID, volume.name, icon, volume);

			volume.addEventListener("visibility_changed", () => {
				if(volume.visible){
					tree.jstree('check_node', node);
				}else{
					tree.jstree('uncheck_node', node);
				}
			});
		};

		let onProfileAdded = (e) => {
			let profile = e.profile;
			let icon = Utils.getMeasurementIcon(profile);
			createNode(measurementID, profile.name, icon, profile);
		};

		let onAnnotationAdded = (e) => {
			let annotation = e.annotation;

			let annotationIcon = `${Potree.resourcePath}/icons/annotation.svg`;
			let parentID = this.annotationMapping.get(annotation.parent);
			let annotationID = createNode(parentID, annotation.title, annotationIcon, annotation);
			this.annotationMapping.set(annotation, annotationID);

			annotation.addEventListener("annotation_changed", (e) => {
				let annotationsRoot = $("#jstree_scene").jstree().get_json("annotations");
				let jsonNode = annotationsRoot.children.find(child => child.data.uuid === annotation.uuid);
				
				$.jstree.reference(jsonNode.id).rename_node(jsonNode.id, annotation.title);
			});

		};

		let onCameraAnimationAdded = (e) => {
			const animation = e.animation;

			const animationIcon = `${Potree.resourcePath}/icons/camera_animation.svg`;
			createNode(otherID, "animation", animationIcon, animation);
		};

		let onOrientedImagesAdded = (e) => {
			const images = e.images;

			const imagesIcon = `${Potree.resourcePath}/icons/picture.svg`;
			const node = createNode(imagesID, "images", imagesIcon, images);

			images.addEventListener("visibility_changed", () => {
				if(images.visible){
					tree.jstree('check_node', node);
				}else{
					tree.jstree('uncheck_node', node);
				}
			});
		};

		let onImages360Added = (e) => {
			const images = e.images;

			const imagesIcon = `${Potree.resourcePath}/icons/picture.svg`;
			const node = createNode(imagesID, "360° images", imagesIcon, images);

			images.addEventListener("visibility_changed", () => {
				if(images.visible){
					tree.jstree('check_node', node);
				}else{
					tree.jstree('uncheck_node', node);
				}
			});
		};

		const onGeopackageAdded = (e) => {
			const geopackage = e.geopackage;

			const geopackageIcon = `${Potree.resourcePath}/icons/triangle.svg`;
			const tree = $(`#jstree_scene`);
			const parentNode = "vectors";

			for(const layer of geopackage.node.children){
				const name = layer.name;

				let shpPointsID = tree.jstree('create_node', parentNode, { 
						"text": name, 
						"icon": geopackageIcon,
						"object": layer,
						"data": layer,
					}, 
					"last", false, false);
				tree.jstree(layer.visible ? "check_node" : "uncheck_node", shpPointsID);
			}

		};

		this.viewer.scene.addEventListener("pointcloud_added", onPointCloudAdded);
		this.viewer.scene.addEventListener("measurement_added", onMeasurementAdded);
		this.viewer.scene.addEventListener("profile_added", onProfileAdded);
		this.viewer.scene.addEventListener("volume_added", onVolumeAdded);
		this.viewer.scene.addEventListener("camera_animation_added", onCameraAnimationAdded);
		this.viewer.scene.addEventListener("oriented_images_added", onOrientedImagesAdded);
		this.viewer.scene.addEventListener("360_images_added", onImages360Added);
		this.viewer.scene.addEventListener("geopackage_added", onGeopackageAdded);
		this.viewer.scene.addEventListener("polygon_clip_volume_added", onVolumeAdded);
		this.viewer.scene.annotations.addEventListener("annotation_added", onAnnotationAdded);

		let onMeasurementRemoved = (e) => {
			let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
			let jsonNode = measurementsRoot.children.find(child => child.data.uuid === e.measurement.uuid);
			
			tree.jstree("delete_node", jsonNode.id);
		};

		let onAnnotationRemoved = (e) => {
			let annotationsRoot = $("#jstree_scene").jstree().get_json("annotations");
			let jsonNode = annotationsRoot.children.find(child => child.data.uuid === e.uuid);
			
			tree.jstree("delete_node", jsonNode.id);
		};

		let onVolumeRemoved = (e) => {
			let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
			let jsonNode = measurementsRoot.children.find(child => child.data.uuid === e.volume.uuid);
			
			tree.jstree("delete_node", jsonNode.id);
		};

		let onPolygonClipVolumeRemoved = (e) => {
			let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
			let jsonNode = measurementsRoot.children.find(child => child.data.uuid === e.volume.uuid);
			
			tree.jstree("delete_node", jsonNode.id);
		};

		let onPointCloudRemoved = (e) => {
			let pointCloudRoot = $("#jstree_scene").jstree().get_json("pointclouds");
			let jsonNode = pointCloudRoot.children.find(child => child.data.uuid === e.volume.uuid);
			
			tree.jstree("delete_node", jsonNode.id);
		};

		let onProfileRemoved = (e) => {
			let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
			let jsonNode = measurementsRoot.children.find(child => child.data.uuid === e.profile.uuid);
			
			tree.jstree("delete_node", jsonNode.id);
		};

		this.viewer.scene.annotations.addEventListener("annotation_removed", onAnnotationRemoved);
		this.viewer.scene.addEventListener("pointcloud_removed", onPointCloudRemoved);

		this.viewer.scene.addEventListener("measurement_removed", onMeasurementRemoved);
		this.viewer.scene.addEventListener("volume_removed", onVolumeRemoved);
		this.viewer.scene.addEventListener("polygon_clip_volume_removed", onPolygonClipVolumeRemoved);
		this.viewer.scene.addEventListener("profile_removed", onProfileRemoved);

		{
			let annotationIcon = `${Potree.resourcePath}/icons/annotation.svg`;
			this.annotationMapping = new Map(); 
			this.annotationMapping.set(this.viewer.scene.annotations, annotationsID);
			this.viewer.scene.annotations.traverseDescendants(annotation => {
				let parentID = this.annotationMapping.get(annotation.parent);
				let annotationID = createNode(parentID, annotation.title, annotationIcon, annotation);
				this.annotationMapping.set(annotation, annotationID);
			});
		}

		const scene = this.viewer.scene;
		for(let pointcloud of scene.pointclouds){
			onPointCloudAdded({pointcloud: pointcloud});
		}

		for(let measurement of scene.measurements){
			onMeasurementAdded({measurement: measurement});
		}

		for(let volume of [...scene.volumes, ...scene.polygonClipVolumes]){
			onVolumeAdded({volume: volume});
		}

		for(let animation of scene.cameraAnimations){
			onCameraAnimationAdded({animation: animation});
		}

		for(let images of scene.orientedImages){
			onOrientedImagesAdded({images: images});
		}

		for(let images of scene.images360){
			onImages360Added({images: images});
		}

		for(const geopackage of scene.geopackages){
			onGeopackageAdded({geopackage: geopackage});
		}

		for(let profile of scene.profiles){
			onProfileAdded({profile: profile});
		}

		{
			createNode(otherID, "Camera", null, new THREE.Camera());
		}

		this.viewer.addEventListener("scene_changed", (e) => {
			propertiesPanel.setScene(e.scene);

			e.oldScene.removeEventListener("pointcloud_added", onPointCloudAdded);
			e.oldScene.removeEventListener("measurement_added", onMeasurementAdded);
			e.oldScene.removeEventListener("profile_added", onProfileAdded);
			e.oldScene.removeEventListener("volume_added", onVolumeAdded);
			e.oldScene.removeEventListener("polygon_clip_volume_added", onVolumeAdded);
			e.oldScene.removeEventListener("measurement_removed", onMeasurementRemoved);
			e.oldScene.removeEventListener("profile_removed", onProfileRemoved);

			e.oldScene.annotations.removeEventListener("annotation_removed", onAnnotationRemoved);
			e.oldScene.removeEventListener("pointcloud_removed", onPointCloudRemoved);
			
			e.scene.addEventListener("pointcloud_added", onPointCloudAdded);
			e.scene.addEventListener("measurement_added", onMeasurementAdded);
			e.scene.addEventListener("profile_added", onProfileAdded);
			e.scene.addEventListener("volume_added", onVolumeAdded);
			e.scene.addEventListener("polygon_clip_volume_added", onVolumeAdded);
			e.scene.addEventListener("measurement_removed", onMeasurementRemoved);
			e.scene.addEventListener("profile_removed", onProfileRemoved);
			e.scene.annotations.addEventListener("annotation_removed", onAnnotationRemoved);
			e.scene.addEventListener("pointcloud_removed", onPointCloudRemoved);
		});

	}

	initClippingTool(){


		this.viewer.addEventListener("cliptask_changed", (event) => {
			console.log("TODO");
		});

		this.viewer.addEventListener("clipmethod_changed", (event) => {
			console.log("TODO");
		});

		{
			let elClipTask = $("#cliptask_options");
			elClipTask.selectgroup({title: "Clip Task"});

			elClipTask.find("input").click( (e) => {
				this.viewer.setClipTask(ClipTask[e.target.value]);
			});

			let currentClipTask = Object.keys(ClipTask)
				.filter(key => ClipTask[key] === this.viewer.clipTask);
			elClipTask.find(`input[value=${currentClipTask}]`).trigger("click");
		}

		{
			let elClipMethod = $("#clipmethod_options");
			elClipMethod.selectgroup({title: "Clip Method"});

			elClipMethod.find("input").click( (e) => {
				this.viewer.setClipMethod(ClipMethod[e.target.value]);
			});

			let currentClipMethod = Object.keys(ClipMethod)
				.filter(key => ClipMethod[key] === this.viewer.clipMethod);
			elClipMethod.find(`input[value=${currentClipMethod}]`).trigger("click");
		}

		let clippingToolBar = $("#clipping_tools");

		// CLIP VOLUME
		clippingToolBar.append(this.createToolIcon(
			Potree.resourcePath + '/icons/clip_volume.svg',
			'[title]tt.clip_volume',
			() => {
				let item = this.volumeTool.startInsertion({clip: true}); 

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === item.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		// CLIP POLYGON
		clippingToolBar.append(this.createToolIcon(
			Potree.resourcePath + "/icons/clip-polygon.svg",
			"[title]tt.clip_polygon",
			() => {
				let item = this.viewer.clippingTool.startInsertion({type: "polygon"});

				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === item.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
			}
		));

		{// SCREEN BOX SELECT
			let boxSelectTool = new ScreenBoxSelectTool(this.viewer);

			clippingToolBar.append(this.createToolIcon(
				Potree.resourcePath + "/icons/clip-screen.svg",
				"[title]tt.screen_clip_box",
				() => {
					if(!(this.viewer.scene.getActiveCamera() instanceof THREE.OrthographicCamera)){
						this.viewer.postMessage(`Switch to Orthographic Camera Mode before using the Screen-Box-Select tool.`, 
							{duration: 2000});
						return;
					}
					
					let item = boxSelectTool.startInsertion();

					let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
					let jsonNode = measurementsRoot.children.find(child => child.data.uuid === item.uuid);
					$.jstree.reference(jsonNode.id).deselect_all();
					$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
				}
			));
		}

		{ // REMOVE CLIPPING TOOLS
			clippingToolBar.append(this.createToolIcon(
				Potree.resourcePath + "/icons/remove.svg",
				"[title]tt.remove_all_clipping_volumes",
				() => {

					this.viewer.scene.removeAllClipVolumes();
				}
			));
		}

	}

	initFilters(){
		this.initClassificationList();
		this.initReturnFilters();
		this.initGPSTimeFilters();
		this.initPointSourceIDFilters();

	}

	initAnnotationsManager(){
		let menuAnnotationManager = $('#annotation_manager');
		const btnSaveAnnotations = menuAnnotationManager.find("#btnSaveAnnotations");
		const btnDeleteAnnotations = menuAnnotationManager.find("#btnDeleteAnnotations");
		const btnAddAnnotations = menuAnnotationManager.find("#btnAddAnnotations");
		const btnPack1 = menuAnnotationManager.find("#btnPack1");
		const btnPack2 = menuAnnotationManager.find("#btnPack2");
		const btnPack3 = menuAnnotationManager.find("#btnPack3");
		const btnPack4 = menuAnnotationManager.find("#btnPack4");
		const btnPack5 = menuAnnotationManager.find("#btnPack5");
		const btnPack6 = menuAnnotationManager.find("#btnPack6");
		const btnPack7 = menuAnnotationManager.find("#btnPack7");
		const btnPack8 = menuAnnotationManager.find("#btnPack8");

		const kmSearch = menuAnnotationManager.find("#kmSearch");
		const btnKM = menuAnnotationManager.find("#btnKM");
		const btnKmPlus = menuAnnotationManager.find("#btnKmPlus");
		const btnKmMinus = menuAnnotationManager.find("#btnKmMinus");
		const kmPlayCamera = menuAnnotationManager.find("#kmPlayCamera");
	
		btnSaveAnnotations.click( () => {
			// this.saveAnnotations();
			 this.savePoint();
		});
		btnDeleteAnnotations.click( () => {
			// this.deleteAnnotations();
			this.deletePoint();
		});
		btnAddAnnotations.click( () => {
			// this.addAnnotations();
			this.addPoints();
		});

		btnPack1.click( () => {	this.annotationFilter(500);});
		btnPack2.click( () => { this.annotationFilter(1000);});
		btnPack3.click( () => { this.annotationFilter(1500);});
		btnPack4.click( () => { this.annotationFilter(2000);});
		btnPack5.click( () => { this.annotationFilter(2500);});
		btnPack6.click( () => { this.annotationFilter(3000);});
		btnPack7.click( () => { this.annotationFilter(3500);});
		btnPack8.click( () => { this.annotationFilter(4000);});

		// kmSearch.click( () => { });
		btnKM.click( () => { this.searchKM(0);});
		btnKmPlus.click( () => { this.searchKmPlus();});
		btnKmMinus.click( () => { this.searchKmMinus();});
		kmPlayCamera.click( () => { this.kmPlayCamera();});

		setTimeout(() => { 
			if (window.currentProject) {
				this.loadProject(window.currentProject); //SdioState.readlocalStorage());
			} else {
				setTimeout(() => {
					console.log('Wait to load project');
					if (window.currentProject) this.loadProject(window.currentProject); //SdioState.readlocalStorage());
				}, 1000);
			}
		}, 200);

		window.pointIncNumber=0;

	}
	
	
	async loadProject(project) {

		if (project) {
			// SdioState.savelocalStorage(project);
			// window.location.reload();
			
			// this.clearProject();

			this.viewer.setDescription(project.description);
			let sceneSG = new Potree.Scene();
			sceneSG.pointclouds = [];
			this.viewer.setScene(sceneSG);
			
			// Load current project + pointclouds
			let allPointClouds = await SdioFetch.loadPointClouds();
			for (const pointCloudId of project.pointclouds) {
				const pointcloud = allPointClouds.find(a => a.id === pointCloudId);
				if (pointcloud) {
					Potree.loadPointCloud(pointcloud.path, pointcloud.name, function(e){
						sceneSG.addPointCloud(e.pointcloud);
						
						let position= project.position ? project.position.replaceAll(' ', '').split(',') : undefined; //[2585883.075, 1220244.805, 880.603];
						let target= project.target ? project.target.replaceAll(' ', '').split(',') : undefined; // [2585191.641, 1220085.996, 355.785];
						
						if (position && target) {
							sceneSG.view.position.set(Number(position[0]), Number(position[1]), Number(position[2]));
							sceneSG.view.lookAt(new THREE.Vector3(Number(target[0]), Number(target[1]), Number(target[2])));
						}
						
						let material = e.pointcloud.material;
						material.size = 0.6;
						material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
					});	

				}
			}

			// this.viewer.setDescription(' ... loading annotations ...');
			console.log('Starting Get annotation');
			sdAnnotations = await SdioFetch.getAll("/dynamicdata/annotation", 'projectId=' + project.id + '&sort=positionY');
			if (sdAnnotations && sdAnnotations.length > 0) {
				this.viewer.setDescription(' ... loading ' + sdAnnotations.length + ' annotations ...');
				console.log('Ending Get annotation', sdAnnotations.length);
			} else {
				if (project) this.viewer.setDescription(project.description);
			}
			
			// Active Pack button
			let menuAnnotationManager = $('#annotation_manager');
			const btnPack1 = menuAnnotationManager.find("#btnPack1");
			const btnPack2 = menuAnnotationManager.find("#btnPack2");
			const btnPack3 = menuAnnotationManager.find("#btnPack3");
			const btnPack4 = menuAnnotationManager.find("#btnPack4");
			const btnPack5 = menuAnnotationManager.find("#btnPack5");
			const btnPack6 = menuAnnotationManager.find("#btnPack6");
			const btnPack7 = menuAnnotationManager.find("#btnPack7");
			const btnPack8 = menuAnnotationManager.find("#btnPack8");
			if (sdAnnotations.length > 0) btnPack1[0].hidden = false;
			if (sdAnnotations.length > 500) btnPack2[0].hidden = false;
			if (sdAnnotations.length > 1000) btnPack3[0].hidden = false;
			if (sdAnnotations.length > 1500) btnPack4[0].hidden = false;
			if (sdAnnotations.length > 2000) btnPack5[0].hidden = false;
			if (sdAnnotations.length > 2500) btnPack6[0].hidden = false;
			if (sdAnnotations.length > 3000) btnPack7[0].hidden = false;
			if (sdAnnotations.length > 3500) btnPack8[0].hidden = false;
			
			

			if (project) this.viewer.setDescription(project.description);
			// for (let i = 0; i < sdAnnotations.length; i += 100) {
				// 	if (i > 400) {
			// 		break;
			// 	}
			// 	console.log('Math.round(i/100)',i);
			// 	let max = i + 100;
			// 	if (max > sdAnnotations.length - 1) {
			// 		max = sdAnnotations.length ;
			// 	}
			// 	this.waitToLoadingAnnotation(sdAnnotations.slice(i,max),max, project);
			// }

			// Simplon 127_1B + Simplon 119_1l
			if (project && project.id == '64a1d52f745a10001e5effb1' || project.id == '645a371b944a3a0025233c69'){
				features = await SdioFetch.loadAxisFeatures(project.id);
				this.loadCameraKm(600);
				const kmTitle = menuAnnotationManager.find("#kmTitle");
				const kmButton = menuAnnotationManager.find("#kmButton");
				const kmSearch = menuAnnotationManager.find("#kmSearch");
				kmTitle[0].hidden = false;
				kmButton[0].hidden = false;
				kmSearch[0].value = features[0].properties.km.toFixed(3);
			}

			// Create profile Profile
			if (false) {
				let profiles = [];
				if (features) {
					for (const feature of features) {
						// add a polyline
							let path = [];
							for (let index = 0; index < feature.geometry.coordinates.length; index++) {
								const coordinate = feature.geometry.coordinates[index];
								let z = 670;
								if (coordinate.length == 3) z = coordinate[2];
								path.push(coordinate[0], coordinate[1], z + 1);
								// if (index > 100) break;
							}
				
							let lineGeometry = new LineGeometry();
				
							lineGeometry.setPositions(path);
							lineGeometry.radius = 1;
							let lineMaterial = new LineMaterial({ 
								color: 0xce75e0, 
								dashSize: 5, 
								gapSize: 2,
								linewidth: 5,
								resolution:  new THREE.Vector2(1000, 1000),
							});
				
							viewer.addEventListener("update", () => {
								viewer.renderer.getSize(lineMaterial.resolution);
								
							});
				
							const line = new Line2(lineGeometry, lineMaterial);
							viewer.scene.scene.add(line);

							
							// //Create profile :
							let profile = new Potree.Profile();
							profile.name = "Elevation Profile " + feature.properties.id;
							profile.setWidth(0.5)
							for (let c = 0; c < feature.geometry.coordinates.length; c++) {
								const coordinate = feature.geometry.coordinates[c];
								profile.addMarker(new THREE.Vector3(coordinate[0], coordinate[1], 700));
								// if (c > 1000) break;
							}

							profiles.push(profile);
							
						}
					}
					
					for (let index = 0; index < profiles.length; index++) {
						try {
							const profile = profiles[index];
							// if (index > 2) break;
							setTimeout(function () {
								viewer.scene.addProfile(profile);
							}, 200);						
						} catch (error) {
							console.log('Error loading profile', error);
						}
						
					}
			
			}

				// const geometry = new THREE.BoxGeometry( 100, 100, 100 );
				// const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
				// const cube = new THREE.Mesh( geometry, material );
				// cube.position.x = 2654917.171;
				// cube.position.y = 1120896.078;
				// cube.position.z = 665.488;
				// this.viewer.scene.scene.add( cube );

		}
		
	}

	// Loading package annotation
	async waitToLoadingAnnotation (x, lastValue, proj) {
		console.log('Wait to loading annotation');
		setTimeout(function () {
			console.log('Now loading points');
			SdioFetch.loadAnnotations(x);
			if (proj) this.viewer.setDescription(proj.description + ' (' + lastValue + '/' + sdAnnotations.length + ')');
		}, 200);
	}


	// Clear project / annotation / pointcloud
	clearProject() {
		
		const clearViewer = (viewer) => { let scene = new Potree.Scene(); viewer.setScene(scene); }

		window.viewer.scene.pointclouds.forEach(function (layer) {
			window.viewer.scene.scenePointCloud.remove(layer);
		});
		window.viewer.scene.pointclouds = [];
	
		// let jsonNode = annotationsRoot.children.find(child => child.data.uuid === annotation.uuid);
		// $.jstree.reference(jsonNode.id).deselect_all();
		// $.jstree.reference(jsonNode.id).select_node(jsonNode.id);
	}

	// Create camera navigation
	loadCameraKm(duration){
		let elScene = $("#menu_scene");
		let elProperties = elScene.next().find("#scene_object_properties");
		let propertiesPanel = new PropertiesPanel(elProperties, this.viewer);
		let profileName = "Camera profile KM";

		// check if always loaded
		let cameraExist = false;
		if (this.viewer.scene.cameraAnimations){
			for (const cameraAnimation of this.viewer.scene.cameraAnimations) {
				if (profileName == cameraAnimation.name) {
					cameraExist = true;
					for (const c of propertiesPanel.container) {
						c.innerHTML = `<div id="scene_object_properties"></div>`;
					}
					propertiesPanel.setCameraAnimation(cameraAnimation);	
					break;
				}
			}
		}

		if (features && !cameraExist) {
			let animationKM = new CameraAnimation(this.viewer);
			let positions = [];
			let targets = [];

			for (const feature of features) {
				for (let index = 0; index < feature.geometry.coordinates.length; index++) {
					const coordinate = feature.geometry.coordinates[index];
					let z = 670;
					if (coordinate.length == 3) z = coordinate[2];
					targets.push([coordinate[0], coordinate[1], z + 1]);

					if (index != 0) {
						const coordinateCam = feature.geometry.coordinates[index - 1];
						if (coordinateCam.length == 3) z = coordinateCam[2];
						positions.push([coordinateCam[0], coordinateCam[1], z + 2]);
					} else {
						const coordinateCam = feature.geometry.coordinates[index];
						if (coordinateCam.length == 3) z = coordinateCam[2];
						positions.push([coordinateCam[0] - 5, coordinateCam[1] + 1, z + 2]);
					}

					//  if (index > 20) break;
				}

			}

			for(let i = 0; i < positions.length; i++){
				const cp = animationKM.createControlPoint();
				cp.position.set(...positions[i]);
				cp.target.set(...targets[i]);
			}

			animationKM.name = profileName; // + feature.properties.id;
			animationKM.visible = true;
			animationKM.duration = duration; // seconde
			animationKM.setVisible(false);
			this.viewer.scene.addCameraAnimation(animationKM);
		
			propertiesPanel.setCameraAnimation(animationKM);

		}

		
	}

	exportProfilePoints() {

		let indexProfile = 0; // index in the features files (simplon-features.js)

		let menuProfil = $('#profile_window');
		const btnProfil = menuProfil.find("#potree_export_point");
		btnProfil[0].click();
		let points = [];
		let currentProfile = features[indexProfile].geometry.coordinates;
		let nearestPoints = [];
		let newProfilePoints = [];
		for (let u = 0; u < currentProfile.length; u++) {
			const coordinate = currentProfile[u];
			nearestPoints.push({profilePoint: coordinate, points: []});
		}

		if (window.profileDataPosition){
			for (let i = 0; i < window.profileDataPosition.length; i++) {
				let p = [window.profileDataPosition[i],window.profileDataPosition[++i],window.profileDataPosition[++i]];
				points.push(p);
				for (let u = 0; u < currentProfile.length; u++) {
					const coordinate = currentProfile[u];
					if (Math.pow(coordinate[0] - p[0], 2) < 10 && Utils.dist2D(coordinate, p) < 0.5) {
						nearestPoints[u].points.push(p);
					}
				}
			}
			
			// filter lower points for each points
			for (let u = 0; u < nearestPoints.length; u++) {
				const points = nearestPoints[u].points;
				let lowerPoint = undefined;
				if ( points && points.length > 0){
					for (let o = 0; o < points.length; o++) {
						if (!lowerPoint) lowerPoint = points[o];
						if (lowerPoint[2] > points[o][2]) {
							lowerPoint = points[o];
						}					
					}
					if (lowerPoint) {
						nearestPoints[u].points = [];
						nearestPoints[u].points.push(lowerPoint);
						newProfilePoints.push([nearestPoints[u].profilePoint[0],nearestPoints[u].profilePoint[1],lowerPoint[2]]);
						console.log('points', lowerPoint, lowerPoint[2]);
					}
				} else {
					console.log('no points in current profiles', nearestPoints[u]);
				}
				
			}

			console.log('new profile', newProfilePoints);
		}
	}

	searchKM(distKm){
		let menuAnnotationManager = $('#annotation_manager');
		const kmSearch = menuAnnotationManager.find("#kmSearch");
		let kmString = Number(kmSearch[0].value);
		let km = Number(kmString) + distKm;
		if (distKm) kmSearch[0].value = km.toFixed(3);
		let StartKm = features[0].properties.km;

		let profileAxisPoint = undefined;
		let profileDirPoint = undefined;
		

		const material = new THREE.MeshBasicMaterial({color: 0xFF0000});
		material.transparent = true;
		material.opacity = 0.5;
		material.wireframe = true;
		let sphere = new THREE.Mesh(
			new THREE.SphereGeometry(0.1, 8, 8),
			material
		);
		let sphereName = "kilometer-sphere";
		// Clean old object
		var selectedObject = this.viewer.scene.scene.getObjectByName(sphereName);
		this.viewer.scene.scene.remove( selectedObject );
		// Add new
		sphere.name = sphereName
		viewer.scene.scene.add(sphere);
		
		// Calculate position;
		if (features && km && StartKm){
			let pos = features[0].geometry.coordinates;
			let endKm = StartKm + (Utils.distanceTotal(pos) / 1000);
			if (km > endKm) {
				kmSearch[0].value = endKm.toFixed(3) - 0.001;
				km = endKm.toFixed(3) - 0.001;
			}
				
			if (pos && pos.length > 1) {
				let totalDistance = StartKm;
				let diffKm = 0;
				for (let i = 1; i < pos.length; i++) {
					let founded = false;
					let dist = (Utils.dist2D(pos[i-1], pos[i]) / 1000);
					totalDistance += dist;
					if (totalDistance.toFixed(4) == km.toFixed(4)){
						let kmfirstPoint = totalDistance - dist;
						diffKm = km - kmfirstPoint;
						founded = true;
					} else if (totalDistance > km){
						let kmfirstPoint = totalDistance - dist;
						diffKm = km - kmfirstPoint;
						founded = true;
					}
					
					if (founded) {
						const newPoint = Utils.getPointOnLine(pos[i-1][0], pos[i-1][1], pos[i][0], pos[i][1], diffKm * 1000);
						const newPointCamera = Utils.getPointOnLine(pos[i-1][0], pos[i-1][1], pos[i][0], pos[i][1], (diffKm * 1000)- 5);
						let endPosition =  new THREE.Vector3().fromArray([newPointCamera.x,newPointCamera.y,pos[i][2] + 2]);
						let endTarget = new THREE.Vector3().fromArray([newPoint.x,newPoint.y,pos[i][2] + 0.3]);
						sphere.position.copy(endTarget);
						profileAxisPoint = endTarget;
						profileDirPoint = new THREE.Vector3(pos[i-1][0], pos[i-1][1], pos[i-1][2]);
						Utils.moveTo(this.viewer.scene, endPosition, endTarget);

						// Test
						let distCumul = Utils.distanceCumulative(pos,[newPoint.x, newPoint.y])
						let distCtrl = StartKm + (distCumul / 1000);
						console.log('totalDistance',endPosition, endTarget, km, distCtrl, newPoint, (km-distCtrl).toFixed(6));

						break;
					}

				}
			}

			// Create profile
			if (profileAxisPoint && profileDirPoint) {
				const start2d = this.getPerpendicularPoint(profileAxisPoint.x, profileAxisPoint.y, profileDirPoint.x, profileDirPoint.y, 5);
				const end2d = this.getPerpendicularPoint(profileAxisPoint.x, profileAxisPoint.y, profileDirPoint.x, profileDirPoint.y, -5);
				let start = new THREE.Vector3(start2d[0],start2d[1],profileAxisPoint.z);
				let end = new THREE.Vector3(end2d[0],end2d[1],profileAxisPoint.z);
				console.log('profile', start, profileAxisPoint, end, profileDirPoint)
				
				// Clean
	
				// Add
				$('#menu_measurements').next().slideDown();
				let profile = this.profileTool.insertionProfile({start: start, end: end});
				let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
				let jsonNode = measurementsRoot.children.find(child => child.data.uuid === profile.uuid);
				$.jstree.reference(jsonNode.id).deselect_all();
				$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
				
			}
		}

	}

	getPerpendicularPoint(x1, y1, xs, ys, distance) {
		let m = (ys - y1) / (xs - x1);
		let slope = -1 / m;
		if (distance > 0) {
			const x2 = x1 + Math.sqrt(distance ** 2 / (1 + slope ** 2));
			const y2 = y1 + slope * (x2 - x1);
			return [x2, y2];
		} else {
			const x2 = x1 - Math.sqrt(distance ** 2 / (1 + slope ** 2));
			const y2 = y1 + slope * (x2 - x1);
			return [x2, y2];
		}
	}
	
	searchKmPlus(){
		this.searchKM(0.01);
	}

	searchKmMinus(){
		this.searchKM(-0.01);
	}

	kmPlayCamera() {
		this.loadCameraKm(600);
	}

	annotationFilter(nbPt) {
	
		// this.exportProfilePoints();
		
		let project = SdioState.readlocalStorage();
		this.viewer.setDescription('Clean all annotation');
		this.viewer.scene.removeAllMeasurements();

		let start = nbPt - 500;
		let end = nbPt;
		if (end > sdAnnotations.length) end = sdAnnotations.length;
		// console.log('start; end', start,end);
		for (let i = start; i < end; i += 100) {
			let max = i + 100;
			if (max > end - 1) {
				max = end ;
			}
			this.waitToLoadingAnnotation(sdAnnotations.slice(i, max),max, project);
		}
	}

	addPoints() {

		window.pointIncNumber++
		window.newObjectAdding = true;

		// Get Last number
		let scene = this.viewer.scene;
		let measurements = [...scene.measurements, ...scene.profiles, ...scene.volumes];
		let incNum = 0;
		for (const m of measurements) {
			if (m.name) {
				let curNum =  +m.name;
				if (curNum > incNum ) incNum = curNum;
			}
		}
		if (incNum != 0) window.pointIncNumber = incNum + 1;


		// Set default
		let menuAnnotationManager = $('#annotation_manager');
		const pointCodeLabel = ''; // menuAnnotationManager.find("#pointCode");
		const pointCodeNumberLabel = menuAnnotationManager.find("#pointCodeNumber");
		let pointCode = ''; //pointCodeLabel[0].value;
		let pointCodeNumber = pointCodeNumberLabel[0].value;
		SdioUtils.setDefaultVariable(pointCode, pointCodeNumber);

		$('#menu_measurements').next().slideDown();
		let measurement = this.measuringTool.startInsertion({
			showDistances: false,
			showAngles: false,
			showCoordinates: true,
			showArea: false,
			closed: true,
			maxMarkers: 1,
			name: window.pointIncNumber,
			codeString: pointCode,
			code: pointCodeNumber,
			projectId: SdioState.getProjectId()
		});

		let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
		let jsonNode = measurementsRoot.children.find(child => child.data.uuid === measurement.uuid);
		$.jstree.reference(jsonNode.id).deselect_all();
		$.jstree.reference(jsonNode.id).select_node(jsonNode.id);

	}

	deletePoint() {
		if (window.currentObject && window.currentObject.userData.id) {
			// if exist in DB => Delete
			SdioFetch.delete("/dynamicdata/annotation", window.currentObject.userData.id);
			this.viewer.scene.removeMeasurement(currentObject);
		} else {
			console.log('Delete : no object');
		}
	}
	async savePoint() {
		
		if (window.currentObject) {

			let menuAnnotationManager = $('#annotation_manager');
			const pointName = menuAnnotationManager.find("#pointName");
			const pointCode = '';// menuAnnotationManager.find("#pointCode");
			const pointCodeNumber = menuAnnotationManager.find("#pointCodeNumber");
			const pointCoordY = menuAnnotationManager.find("#pointCoordY");
			const pointCoordX = menuAnnotationManager.find("#pointCoordX");
			const pointCoordZ = menuAnnotationManager.find("#pointCoordZ");
			const pointKm = menuAnnotationManager.find("#pointKm");
			
			// Set default
			window.pointCodeDefault = pointCode;
			window.pointCodeNumberDefault = pointCodeNumber;
			
			window.currentObject.name = pointName[0].value;
			// window.currentObject.userData.codeString = pointCode[0].value;
			window.currentObject.userData.code = pointCodeNumber[0].value;
			window.currentObject.points[0].position.x = pointCoordY[0].value;
			window.currentObject.points[0].position.y = pointCoordX[0].value;
			window.currentObject.points[0].position.z = pointCoordZ[0].value;
			
			// Calculate KM
			if (features && features.length > 0 && features[0].properties.km) {
				const points = features[0].geometry.coordinates;
				const startKm = features[0].properties.km;
				pointKm[0].value = (startKm + (Utils.distanceCumulative(points, [pointCoordY[0].value, pointCoordX[0].value])/ 1000)).toFixed(4);
				window.currentObject.userData.km = pointKm[0].value;
			}

			SdioFetch.savePoint(window.currentObject);
		
		} else {
			console.log('Save : no object');
		}

	}
	
	addAnnotations() {
		console.log('add points');
		// if (window.activeMultiAnnotations) {
		// 	window.activeMultiAnnotations = false;
		// } else {
		 	window.activeMultiAnnotations = true;
		// }

		$('#menu_measurements').next().slideDown(); ;
		let annotation = this.viewer.annotationTool.startInsertion();
		window.pointIncNumber++;

		// Set default value
		annotation.title = window.pointIncNumber;
		annotation.codeString = '';
		annotation.code = 0;
		annotation.projectId = SdioState.getProjectId();

		let annotationsRoot = $("#jstree_scene").jstree().get_json("annotations");
		let jsonNode = annotationsRoot.children.find(child => child.data.uuid === annotation.uuid);
		$.jstree.reference(jsonNode.id).deselect_all();
		$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
	}
	
	deleteAnnotations() {
		if (window.currentObject) {
			// if exist in DB => Delete
			SdioFetch.delete("/dynamicdata/annotation", window.currentObject.id);
		} else {
			console.log('Delete : no object');
		}
	}
	async saveAnnotations() {
		
		if (window.currentObject) {
			let menuAnnotationManager = $('#annotation_manager');
			const pointName = menuAnnotationManager.find("#pointName");
			const pointCode = menuAnnotationManager.find("#pointCode");
			const pointCodeNumber = menuAnnotationManager.find("#pointCodeNumber");
			const pointCoordY = menuAnnotationManager.find("#pointCoordY");
			const pointCoordX = menuAnnotationManager.find("#pointCoordX");
			const pointCoordZ = menuAnnotationManager.find("#pointCoordZ");
			window.currentObject.title = pointName[0].value;
			// window.currentObject.codeString = pointCode[0].value;
			window.currentObject.code = pointCodeNumber[0].value;
			window.currentObject.position.x = pointCoordY[0].value;
			window.currentObject.position.y = pointCoordX[0].value;
			window.currentObject.position.z = pointCoordZ[0].value;

			SdioFetch.saveAnnotation(window.currentObject);
		
		} else {
			console.log('Save : no object');
		}

	}

	initReturnFilters(){
		let elReturnFilterPanel = $('#return_filter_panel');

		{ // RETURN NUMBER
			let sldReturnNumber = elReturnFilterPanel.find('#sldReturnNumber');
			let lblReturnNumber = elReturnFilterPanel.find('#lblReturnNumber');

			sldReturnNumber.slider({
				range: true,
				min: 0, max: 7, step: 1,
				values: [0, 7],
				slide: (event, ui) => {
					this.viewer.setFilterReturnNumberRange(ui.values[0], ui.values[1])
				}
			});

			let onReturnNumberChanged = (event) => {
				let [from, to] = this.viewer.filterReturnNumberRange;

				lblReturnNumber[0].innerHTML = `${from} to ${to}`;
				sldReturnNumber.slider({values: [from, to]});
			};

			this.viewer.addEventListener('filter_return_number_range_changed', onReturnNumberChanged);

			onReturnNumberChanged();
		}

		{ // NUMBER OF RETURNS
			let sldNumberOfReturns = elReturnFilterPanel.find('#sldNumberOfReturns');
			let lblNumberOfReturns = elReturnFilterPanel.find('#lblNumberOfReturns');

			sldNumberOfReturns.slider({
				range: true,
				min: 0, max: 7, step: 1,
				values: [0, 7],
				slide: (event, ui) => {
					this.viewer.setFilterNumberOfReturnsRange(ui.values[0], ui.values[1])
				}
			});

			let onNumberOfReturnsChanged = (event) => {
				let [from, to] = this.viewer.filterNumberOfReturnsRange;

				lblNumberOfReturns[0].innerHTML = `${from} to ${to}`;
				sldNumberOfReturns.slider({values: [from, to]});
			};

			this.viewer.addEventListener('filter_number_of_returns_range_changed', onNumberOfReturnsChanged);

			onNumberOfReturnsChanged();
		}
	}

	initGPSTimeFilters(){

		let elGPSTimeFilterPanel = $('#gpstime_filter_panel');

		{
			let slider = new HierarchicalSlider({
				levels: 4,
				slide: (event) => {
					this.viewer.setFilterGPSTimeRange(...event.values);
				},
			});

			let initialized = false;

			let initialize = () => {
				
				let elRangeContainer = $("#gpstime_multilevel_range_container");
				elRangeContainer[0].prepend(slider.element);

				let extent = this.viewer.getGpsTimeExtent();

				slider.setRange(extent);
				slider.setValues(extent);


				initialized = true;
			};

			this.viewer.addEventListener("update", (e) => {
				let extent = this.viewer.getGpsTimeExtent();
				let gpsTimeAvailable = extent[0] !== Infinity;

				if(!initialized && gpsTimeAvailable){
					initialize();
				}

				slider.setRange(extent);
			});
		}


		{
			
			const txtGpsTime = elGPSTimeFilterPanel.find("#txtGpsTime");
			const btnFindGpsTime = elGPSTimeFilterPanel.find("#btnFindGpsTime");

			let targetTime = null;

			txtGpsTime.on("input", (e) => {
				const str = txtGpsTime.val();

				if(!isNaN(str)){
					const value = parseFloat(str);
					targetTime = value;

					txtGpsTime.css("background-color", "")
				}else{
					targetTime = null;

					txtGpsTime.css("background-color", "#ff9999")
				}

			});

			btnFindGpsTime.click( () => {
				
				if(targetTime !== null){
					viewer.moveToGpsTimeVicinity(targetTime);
				}
			});
		}

	}

	initPointSourceIDFilters() {
		let elPointSourceIDFilterPanel = $('#pointsourceid_filter_panel');

		{
			let slider = new HierarchicalSlider({
				levels: 4,
				range: [0, 65535],
				precision: 1,
				slide: (event) => {
					let values = event.values;
					this.viewer.setFilterPointSourceIDRange(values[0], values[1]);
				}
			});

			let initialized = false;

			let initialize = () => {
				elPointSourceIDFilterPanel[0].prepend(slider.element);

				initialized = true;
			};

			this.viewer.addEventListener("update", (e) => {
				let extent = this.viewer.filterPointSourceIDRange;

				if(!initialized){
					initialize();

					slider.setValues(extent);
				}
				
			});
		}

		// let lblPointSourceID = elPointSourceIDFilterPanel.find("#lblPointSourceID");
		// let elPointSourceID = elPointSourceIDFilterPanel.find("#spnPointSourceID");

		// let slider = new ZoomableSlider();
		// elPointSourceID[0].appendChild(slider.element);
		// slider.update();

		// slider.change( () => {
		// 	let range = slider.chosenRange;
		// 	this.viewer.setFilterPointSourceIDRange(range[0], range[1]);
		// });

		// let onPointSourceIDExtentChanged = (event) => {
		// 	let range = this.viewer.filterPointSourceIDExtent;
		// 	slider.setVisibleRange(range);
		// };

		// let onPointSourceIDChanged = (event) => {
		// 	let range = this.viewer.filterPointSourceIDRange;

		// 	let precision = 1;
		// 	let from = `${Utils.addCommas(range[0].toFixed(precision))}`;
		// 	let to = `${Utils.addCommas(range[1].toFixed(precision))}`;
		// 	lblPointSourceID[0].innerHTML = `${from} to ${to}`;

		// 	slider.setRange(range);
		// };

		// this.viewer.addEventListener('filter_point_source_id_range_changed', onPointSourceIDChanged);
		// this.viewer.addEventListener('filter_point_source_id_extent_changed', onPointSourceIDExtentChanged);

	}

	initClassificationList(){
		let elClassificationList = $('#classificationList');

		let addClassificationItem = (code, name) => {
			const classification = this.viewer.classifications[code];
			const inputID = 'chkClassification_' + code;
			const colorPickerID = 'colorPickerClassification_' + code;

			const checked = classification.visible ? "checked" : "";

			let element = $(`
				<li>
					<label style="whitespace: nowrap; display: flex">
						<input id="${inputID}" type="checkbox" ${checked}/>
						<span style="flex-grow: 1">${name}</span>
						<input id="${colorPickerID}" style="zoom: 0.5" />
					</label>
				</li>
			`);

			const elInput = element.find('input');
			const elColorPicker = element.find(`#${colorPickerID}`);

			elInput.click(event => {
				this.viewer.setClassificationVisibility(code, event.target.checked);
			});

			let defaultColor = classification.color.map(c => c *  255).join(", ");
			defaultColor = `rgb(${defaultColor})`;


			elColorPicker.spectrum({
				// flat: true,
				color: defaultColor,
				showInput: true,
				preferredFormat: 'rgb',
				cancelText: '',
				chooseText: 'Apply',
				move: color => {
					let rgb = color.toRgb();
					const c = [rgb.r / 255, rgb.g / 255, rgb.b / 255, 1];
					classification.color = c;
				},
				change: color => {
					let rgb = color.toRgb();
					const c = [rgb.r / 255, rgb.g / 255, rgb.b / 255, 1];
					classification.color = c;
				}
			});

			elClassificationList.append(element);
		};

		const addToggleAllButton = () => { // toggle all button
			const element = $(`
				<li>
					<label style="whitespace: nowrap">
						<input id="toggleClassificationFilters" type="checkbox" checked/>
						<span>show/hide all</span>
					</label>
				</li>
			`);

			let elInput = element.find('input');

			elInput.click(event => {
				this.viewer.toggleAllClassificationsVisibility();
			});

			elClassificationList.append(element);
		}

		const addInvertButton = () => { 
			const element = $(`
				<li>
					<input type="button" value="invert" />
				</li>
			`);

			let elInput = element.find('input');

			elInput.click( () => {
				const classifications = this.viewer.classifications;
	
				for(let key of Object.keys(classifications)){
					let value = classifications[key];
					this.viewer.setClassificationVisibility(key, !value.visible);
				}
			});

			elClassificationList.append(element);
		};

		const populate = () => {
			addToggleAllButton();
			for (let classID in this.viewer.classifications) {
				addClassificationItem(classID, this.viewer.classifications[classID].name);
			}
			addInvertButton();
		};

		populate();

		this.viewer.addEventListener("classifications_changed", () => {
			elClassificationList.empty();
			populate();
		});

		this.viewer.addEventListener("classification_visibility_changed", () => {

			{ // set checked state of classification buttons
				for(const classID of Object.keys(this.viewer.classifications)){
					const classValue = this.viewer.classifications[classID];

					let elItem = elClassificationList.find(`#chkClassification_${classID}`);
					elItem.prop("checked", classValue.visible);
				}
			}

			{ // set checked state of toggle button based on state of all other buttons
				let numVisible = 0;
				let numItems = 0;
				for(const key of Object.keys(this.viewer.classifications)){
					if(this.viewer.classifications[key].visible){
						numVisible++;
					}
					numItems++;
				}
				const allVisible = numVisible === numItems;

				let elToggle = elClassificationList.find("#toggleClassificationFilters");
				elToggle.prop("checked", allVisible);
			}
		});
	}

	initAccordion(){
		$('.accordion > h3').each(function(){
			let header = $(this);
			let content = $(this).next();

			//header.addClass('accordion-header ui-widget');
			//content.addClass('accordion-content ui-widget');

			content.hide();

			header.click(() => {
				content.slideToggle();
			});
		});

		let languages = [
			["EN", "en"],
			["FR", "fr"],
			["DE", "de"],
			["ES", "es"],
			["IT", "it"]
		];
		// let languages = [
		// 	["EN", "en"],
		// 	["FR", "fr"],
		// 	["DE", "de"],
		// 	["JP", "jp"],
		// 	["ES", "es"],
		// 	["SE", "se"],
		// 	["ZH", "zh"],
		// 	["IT", "it"]
		// ];

		let elLanguages = $('#potree_languages');
		for(let i = 0; i < languages.length; i++){
			let [key, value] = languages[i];
			let element = $(`<a>${key}</a>`);
			element.click(() => this.viewer.setLanguage(value));

			if(i === 0){
				element.css("margin-left", "20px");
			}
			
			elLanguages.append(element);

			if(i < languages.length - 1){
				elLanguages.append($(document.createTextNode(' - ')));	
			}
		}


		// to close all, call
		// $(".accordion > div").hide()

		// to open the, for example, tool menu, call:
		// $("#menu_tools").next().show()
	}

	initAppearance(){

		const sldPointBudget = this.dom.find('#sldPointBudget');

		sldPointBudget.slider({
			value: this.viewer.getPointBudget(),
			min: 100 * 1000,
			max: 10 * 1000 * 1000,
			step: 1000,
			slide: (event, ui) => { this.viewer.setPointBudget(ui.value); }
		});

		this.dom.find('#sldFOV').slider({
			value: this.viewer.getFOV(),
			min: 20,
			max: 100,
			step: 1,
			slide: (event, ui) => { this.viewer.setFOV(ui.value); }
		});

		$('#sldEDLRadius').slider({
			value: this.viewer.getEDLRadius(),
			min: 1,
			max: 4,
			step: 0.01,
			slide: (event, ui) => { this.viewer.setEDLRadius(ui.value); }
		});

		$('#sldEDLStrength').slider({
			value: this.viewer.getEDLStrength(),
			min: 0,
			max: 5,
			step: 0.01,
			slide: (event, ui) => { this.viewer.setEDLStrength(ui.value); }
		});

		$('#sldEDLOpacity').slider({
			value: this.viewer.getEDLOpacity(),
			min: 0,
			max: 1,
			step: 0.01,
			slide: (event, ui) => { this.viewer.setEDLOpacity(ui.value); }
		});

		this.viewer.addEventListener('point_budget_changed', (event) => {
			$('#lblPointBudget')[0].innerHTML = Utils.addCommas(this.viewer.getPointBudget());
			sldPointBudget.slider({value: this.viewer.getPointBudget()});
		});

		this.viewer.addEventListener('fov_changed', (event) => {
			$('#lblFOV')[0].innerHTML = parseInt(this.viewer.getFOV());
			$('#sldFOV').slider({value: this.viewer.getFOV()});
		});

		this.viewer.addEventListener('use_edl_changed', (event) => {
			$('#chkEDLEnabled')[0].checked = this.viewer.getEDLEnabled();
		});

		this.viewer.addEventListener('edl_radius_changed', (event) => {
			$('#lblEDLRadius')[0].innerHTML = this.viewer.getEDLRadius().toFixed(1);
			$('#sldEDLRadius').slider({value: this.viewer.getEDLRadius()});
		});

		this.viewer.addEventListener('edl_strength_changed', (event) => {
			$('#lblEDLStrength')[0].innerHTML = this.viewer.getEDLStrength().toFixed(1);
			$('#sldEDLStrength').slider({value: this.viewer.getEDLStrength()});
		});

		this.viewer.addEventListener('background_changed', (event) => {
			$("input[name=background][value='" + this.viewer.getBackground() + "']").prop('checked', true);
		});

		$('#lblPointBudget')[0].innerHTML = Utils.addCommas(this.viewer.getPointBudget());
		$('#lblFOV')[0].innerHTML = parseInt(this.viewer.getFOV());
		$('#lblEDLRadius')[0].innerHTML = this.viewer.getEDLRadius().toFixed(1);
		$('#lblEDLStrength')[0].innerHTML = this.viewer.getEDLStrength().toFixed(1);
		$('#chkEDLEnabled')[0].checked = this.viewer.getEDLEnabled();
		
		{
			let elBackground = $(`#background_options`);
			elBackground.selectgroup();

			elBackground.find("input").click( (e) => {
				this.viewer.setBackground(e.target.value);
			});

			let currentBackground = this.viewer.getBackground();
			$(`input[name=background_options][value=${currentBackground}]`).trigger("click");
		}

		$('#chkEDLEnabled').click( () => {
			this.viewer.setEDLEnabled($('#chkEDLEnabled').prop("checked"));
		});
	}

	initNavigation(){
		let elNavigation = $('#navigation');
		let sldMoveSpeed = $('#sldMoveSpeed');
		let lblMoveSpeed = $('#lblMoveSpeed');

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + '/icons/earth_controls_1.png',
			'[title]tt.earth_control',
			() => { this.viewer.setControls(this.viewer.earthControls); }
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + '/icons/fps_controls.svg',
			'[title]tt.flight_control',
			() => {
				this.viewer.setControls(this.viewer.fpControls);
				this.viewer.fpControls.lockElevation = false;
			}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + '/icons/helicopter_controls.svg',
			'[title]tt.heli_control',
			() => { 
				this.viewer.setControls(this.viewer.fpControls);
				this.viewer.fpControls.lockElevation = true;
			}
		));

		// elNavigation.append(this.createToolIcon(
		// 	Potree.resourcePath + '/icons/orbit_controls.svg',
		// 	'[title]tt.orbit_control',
		// 	() => { this.viewer.setControls(this.viewer.orbitControls); }
		// ));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + '/icons/focus.svg',
			'[title]tt.focus_control',
			() => { this.viewer.fitToScreen(); }
		));

		// elNavigation.append(this.createToolIcon(
		// 	Potree.resourcePath + "/icons/navigation_cube.svg",
		// 	"[title]tt.navigation_cube_control",
		// 	() => {this.viewer.toggleNavigationCube()}
		// ));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/images/compas.svg",
			"[title]tt.compass",
			() => {
				const visible = !this.viewer.compass.isVisible();
				this.viewer.compass.setVisible(visible);
			}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/camera_animation.svg",
			"[title]tt.camera_animation",
			() => {
				const animation = CameraAnimation.defaultFromView(this.viewer);

				viewer.scene.addCameraAnimation(animation);
			}
		));


		elNavigation.append("<br>");


		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/left.svg",
			"[title]tt.left_view_control",
			() => {this.viewer.setLeftView()}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/right.svg",
			"[title]tt.right_view_control",
			() => {this.viewer.setRightView()}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/front.svg",
			"[title]tt.front_view_control",
			() => {this.viewer.setFrontView()}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/back.svg",
			"[title]tt.back_view_control",
			() => {this.viewer.setBackView()}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/top.svg",
			"[title]tt.top_view_control",
			() => {this.viewer.setTopView()}
		));

		elNavigation.append(this.createToolIcon(
			Potree.resourcePath + "/icons/bottom.svg",
			"[title]tt.bottom_view_control",
			() => {this.viewer.setBottomView()}
		));





		// let elCameraProjection = $(`
		// 	<selectgroup id="camera_projection_options">
		// 		<option id="camera_projection_options_perspective" value="PERSPECTIVE">Perspective</option>
		// 		<option id="camera_projection_options_orthigraphic" value="ORTHOGRAPHIC">Orthographic</option>
		// 	</selectgroup>
		// `);
		// elNavigation.append(elCameraProjection);
		// elCameraProjection.selectgroup({title: "Camera Projection"});
		// elCameraProjection.find("input").click( (e) => {
		// 	this.viewer.setCameraMode(CameraMode[e.target.value]);
		// });
		// let cameraMode = Object.keys(CameraMode)
		// 	.filter(key => CameraMode[key] === this.viewer.scene.cameraMode);
		// elCameraProjection.find(`input[value=${cameraMode}]`).trigger("click");

		let speedRange = new THREE.Vector2(1, 10 * 1000);

		let toLinearSpeed = (value) => {
			return Math.pow(value, 4) * speedRange.y + speedRange.x;
		};

		let toExpSpeed = (value) => {
			return Math.pow((value - speedRange.x) / speedRange.y, 1 / 4);
		};

		sldMoveSpeed.slider({
			value: toExpSpeed(this.viewer.getMoveSpeed()),
			min: 0,
			max: 1,
			step: 0.01,
			slide: (event, ui) => { this.viewer.setMoveSpeed(toLinearSpeed(ui.value)); }
		});

		this.viewer.addEventListener('move_speed_changed', (event) => {
			lblMoveSpeed.html(this.viewer.getMoveSpeed().toFixed(1));
			sldMoveSpeed.slider({value: toExpSpeed(this.viewer.getMoveSpeed())});
		});

		lblMoveSpeed.html(this.viewer.getMoveSpeed().toFixed(1));
	}


	initSettings(){

		{
			$('#sldMinNodeSize').slider({
				value: this.viewer.getMinNodeSize(),
				min: 0,
				max: 1000,
				step: 0.01,
				slide: (event, ui) => { this.viewer.setMinNodeSize(ui.value); }
			});

			this.viewer.addEventListener('minnodesize_changed', (event) => {
				$('#lblMinNodeSize').html(parseInt(this.viewer.getMinNodeSize()));
				$('#sldMinNodeSize').slider({value: this.viewer.getMinNodeSize()});
			});
			$('#lblMinNodeSize').html(parseInt(this.viewer.getMinNodeSize()));
		}

		{
			let elSplatQuality = $("#splat_quality_options");
			elSplatQuality.selectgroup({title: "Splat Quality"});

			elSplatQuality.find("input").click( (e) => {
				if(e.target.value === "standard"){
					this.viewer.useHQ = false;
				}else if(e.target.value === "hq"){
					this.viewer.useHQ = true;
				}
			});

			let currentQuality = this.viewer.useHQ ? "hq" : "standard";
			elSplatQuality.find(`input[value=${currentQuality}]`).trigger("click");
		}

		$('#show_bounding_box').click(() => {
			this.viewer.setShowBoundingBox($('#show_bounding_box').prop("checked"));
		});

		$('#set_freeze').click(() => {
			this.viewer.setFreeze($('#set_freeze').prop("checked"));
		});
	}

}
